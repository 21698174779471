import { useTelegram } from "hooks"
import { useNavigate } from "react-router-dom";

export const OrderPage = () => {
    const navigate = useNavigate();
    const {tg} = useTelegram();
    return (
        <div>
        Order page
        <button onClick={()=>{tg.sendData({a:1})}}> ddd </button>
        <button onClick={()=>{navigate(-1)}}> go back </button>
        </div>
    )
}