import { useCallback, useEffect, useState } from "react";
import { useTelegram } from "hooks";
import { Link } from 'react-router-dom'

export const HomePage = () => {
  const [viewPortHeight, setViewPortHeight]  = useState(0);
  const { tg } = useTelegram();
  
  useEffect(() => {
        tg.MainButton.setParams({
            text: 'Надіслати',
        })
        tg.MainButton.show()
        tg.expand();

        // tg.showPopup({
        //     title: 'This is title PopupButton',
        //     message: 'This is message PopupButton',
        //     buttons: [{type:'close'}],
        //      }, (e)=>{});
        //tg.PopupButton.show();

    }, [tg]);

  useEffect(() => {
        return () => tg.MainButton.hide();
  }) ;

  const onSendData = useCallback(() => {
        const data = {a:'main'}
        tg.sendData(JSON.stringify(data));
  },[tg]);

  const onViewportChanged = useCallback((e) => {
    console.log(e);
    setViewPortHeight(Math.trunc(tg.viewportHeight));
  },[tg]);

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData);
    tg.onEvent('viewportChanged', onViewportChanged);
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
            tg.offEvent('viewportChanged',onViewportChanged);
            
        }
  }, [onSendData,onViewportChanged, tg])

     return (
        <div>Home page

             <Link to="/order" 
                // onClick={()=>{tg.MainButton.hide()}}
             >Order</Link>
             Height: {viewPortHeight}
        </div>
    )
}